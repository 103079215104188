import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ReactPixel from 'react-facebook-pixel';
import { Helmet } from "react-helmet";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {isAndroid, isIOS} from 'react-device-detect';
import AppStore from '../appstore.svg';
import PlayStore from '../playstore.svg';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#f7b74f',
      main: '#F5A623',
      dark: '#ab7418',
      contrastText: '#fff',
    },
  }
});

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://washr.app/">
        Washr, Inc
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(4, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const iOSURL = 'https://apps.apple.com/us/app/fillr/id1438441935';
const playURL = 'https://play.google.com/store/apps/details?id=com.omni.vida';

let toggleHelmet = () => null;
const redirect = () => {
  if(isIOS) window.location = iOSURL;
  if(isAndroid) window.location = playURL;
  if(isAndroid || isIOS) ReactPixel.trackCustom('Download', {});
  if(isAndroid || isIOS) toggleHelmet();
}


const options = {
  autoConfig: true,
  debug: false,
};
ReactPixel.init('2460443880856593', {}, options);

export default function DownloadPage() {
  ReactPixel.pageView();
  const [sendConversion, setSendConversion] = React.useState(false);
  toggleHelmet = setSendConversion;
  const classes = useStyles();
  redirect();

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            Washr
          </Typography>
        </Toolbar>
      </AppBar>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center" style={{paddingBottom: 30}}>
            Washr Download
          </Typography>
          <React.Fragment>
            <Typography variant="h5" gutterBottom>
              Thank you for downloading!
            </Typography>
            <Typography variant="subtitle1">
              You are now being redirected to your phone's app store.  If your browser does not redirect you automatically, please use the buttons below.
            </Typography>

            <div style={{width: '100%', padding: 50, flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
              <a href={iOSURL} onClick={toggleHelmet} target = "_blank">
                <img style={{height:50, width:225}} src={AppStore} />
              </a>
              <a href={playURL} onClick={toggleHelmet} target = "_blank">
                <img style={{height:50, width:225}} src={PlayStore} />
              </a>
            </div>
          </React.Fragment>
        </Paper>
        <Copyright />
        {
          sendConversion && (
            <Helmet>
              <script>
                {`
                  gtag('event', 'conversion', {'send_to': 'AW-697189278/IIoSCM-00cIBEJ6HucwC'});
                `}
              </script>
            </Helmet>
          )
        }
      </main>
    </MuiThemeProvider>
  );
}


// <Button
//   variant="contained"
//   color="primary"
//   onClick={redirect}
//   className={classes.button}
// >
//   Download Washr
// </Button>
