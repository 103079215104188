import '../App.css';
import { Switch, Route } from 'react-router-dom';

import Download from '../pages/';

function Routes() {
  return (
    <Switch>
      <Route path="/" render={props => <Download {...props} />} />
      {/* redirect user to page below if route does not exist */}
      <Route render={props => <Download {...props} />} />
    </Switch>
  );
}

export default Routes;
